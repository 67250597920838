<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa toimeksiantajaa</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>
    <p style="font-size: 18px">{{ partner.name }}</p>
    <partner-form
      :part="partner"
      submit-btn-text="Tallenna"
      @sendpartner="submit($event)"
    ></partner-form>
  </div>
</template>

<script>
import PartnerForm from "../../components/Partner/PartnerForm";
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import { mapMutations, mapActions } from "vuex";

export default {
  title: "Muokkaa toimeksiantajaa",

  components: {
    PartnerForm,
  },

  mixins: [mixins],

  data() {
    return {
      partner: {},
    };
  },

  async created() {
    try {
      this.setLoading(true);

      let res = await axiosMethods.get("/api/v1/partner/getOne/" + this.$route.params.id);
      this.partner = res.data.partner;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapMutations("partner", ["setLoading"]),
    ...mapActions("partner", ["editPartner"]),

    async submit(partner) {
      try {
        await this.editPartner({
          url: `/api/v1/partner/update/${this.$route.params.id}`,
          data: partner,
        });
        this.showPopup("Toimeksiantaja tallennettu", "success");
        this.$router.push("/partners");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
